<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name"/>
    <v-row>
      <v-col>
        <v-card :disabled="loading">
          <v-card-title>
            Import assets
            <v-spacer/>
            <v-btn @click="reset()" text v-if="this.result.length > 0">Reset</v-btn>
            <v-btn v-if="selected.length > 0 && !processing" @click="processResults()" color="primary">Process selected rows</v-btn>
          </v-card-title>
          <v-card-text>
            <v-autocomplete
                :items="projects"
                v-model="project_id"
                @change="loadAssets()"
                :label="$store.getters.translate('select_project')"
                item-text="name"
                item-value="id"
                attach/>
            <v-autocomplete
                v-if="selectedProject"
                :items="selectedProject.tags"
                v-model="selected_tags"
                :label="$store.getters.translate('select_tags')"
                item-text="name.en"
                item-value="id"
                multiple
                attach
                chips
                deletable-chips
                return-object/>
            <v-autocomplete
                :items="fieldsets"
                v-model="selected_fieldset"
                :label="$store.getters.translate('select_fieldset')"
                item-text="name"
                item-value="id"
                attach
                chips
                deletable-chips
                return-object/>
            <v-switch
                v-model="group_documents"
                :label="$store.getters.translate('group')"
                dense/>
            <importer
                v-if="selectedProject && result.length == 0 && selected_fieldset"
                @result="parseResult"
                :fields="selected_fieldset.content"
                :title="selected_fieldset.name"/>
            <v-data-table
                v-if="result.length > 0"
                :headers="data_table_headers"
                :items="result"
                flat
                item-key="import_id"
                v-model="selected"
                :show-select="show_select"
                :group-by="groupBy"
                disable-pagination
                hide-default-footer
                multi-sort
                :loading="processing"
                disabled
                selectable-key="is_selectable"
                :show-expand="false">
              <template v-if="type != 'multiple'" v-slot:item.id="{ item }">
                <v-chip :color="checkProperty(item.id).result ? 'green' : 'orange'" small class="ma-2" text-color="white">
                  {{ item.id }}
                </v-chip>
              </template>
              <template v-slot:item.import_property="{ item }">
                <v-combobox
                    :disabled="processing"
                    v-model="item.selected_targets"
                    :items="item.targets"
                    attach
                    class="mt-4"
                    dense
                    chips
                    small-chips
                    multiple
                    deletable-chips
                    outlined>
                  <template v-slot:target="{ target }">
                    <v-chip :color="checkProperty(target).result ? 'green' : 'orange'" small class="ma-2" text-color="white">
                      {{ item.id }}
                    </v-chip>
                  </template>
                </v-combobox>
              </template>
              <template v-slot:item.equipment_type="{ item }">
                <v-autocomplete
                    :disabled="processing"
                    v-if="checkEquipment(item.equipment_type).forms.length > 0"
                    :items="checkEquipment(item.equipment_type).forms"
                    :value="checkEquipment(item.equipment_type).forms"
                    v-model="item.forms"
                    :hint="'DAR sheets for equipment type ' + item.equipment_type"
                    persistent-hint
                    attach
                    chips
                    multiple
                    deletable-chips
                    item-text="full_name"
                    item-value="id"
                    small-chips
                    return-object
                    class="mt-4"
                    dense
                    outlined/>
                <span v-else class="text--secondary">{{ item.equipment_type }} has no linked forms</span>
              </template>
              <template v-slot:item.from="{ item }">
                <v-chip :color="checkProperty(item.from).result ? 'green' : 'red'" small class="ma-2" text-color="white">
                  {{ item.from }}
                </v-chip>
              </template>
              <template v-slot:item.to="{ item }">
                <v-chip :color="checkProperty(item.to).result ? 'green' : 'red'" small class="ma-2" text-color="white">
                  {{ item.to }}
                </v-chip>
              </template>
              <!--<template v-slot:expanded-item="{ headers, item }">-->
              <!--<template v-slot:expanded-item="{ headers }">
                <td class="m-3" :colspan="headers.length"></td>
              </template>-->
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import findParentInArray from "../../plugins/findParentInArray";
import helpFunctions from "../../plugins/helpFunctions";
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
import Importer from "../../components/commonComponents/Importer";

export default {
  components: {
    BaseBreadcrumb,
    Importer,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("importassets"),
        name: "importassets",
        model: "importasset",
      },
      fields: [],
      fieldsets: [],
      group_documents: false,
      selected_fieldset: [],
      show_select: true,
      to_be_processed: [],
      selected: [],
      loading: false,
      result: [],
      result_headers: [],
      data_table_headers: [],
      project_id: null,
      projects: [],
      selected_tags: [],
      tags: [],
      assets: [],
      equipment: [],
      check: false,
      type: "",
      processing: false,
      html_header: "",
      processDocumentsCounter: 0,
    };
  },
  created() {
    this.html_header = helpFunctions.html_header;
    this.load();
  },
  methods: {
    load() {
      this.getProjects();
      this.getEquipment();
      this.getFieldsets();
    },
    parseResult(rows) {
      this.result_headers = [];
      //fill result_headers that will be used later to replace shortcodes in document html
      this.selected_fieldset.content.forEach((header) => {
        this.result_headers.push({
          text: header.label,
          value: header.key,
        });
        if (header.key === "cable_nr" || header.key === "from" || header.key === "to" ||
            header.key === "tag_nr" || header.key === "import_property" || header.key === "equipment_type") {
          this.data_table_headers.push({
            text: header.key === "equipment_type" ? "Forms linked to equipment type" : header.label,
            value: header.key,
          });
        }
      });
      this.data_table_headers.push({ text: "", value: "data-table-expand" });
      rows.forEach((row) => {
        //example of import property in excel: UTILITIES/PUBLIC ANOUNCEMENT/MEZZANINEDECK
        row.targets = [];
        row.selected_targets = [];
        if(row.import_property) {
          row.targets = row.import_property.split("/");
          row.targets.forEach((target) => {
            target.toUpperCase();
          });
          row.selected_targets = row.targets;
        }
        row.import_id = this.uid();
        //search forms (DAR-AE-101, etc)
        row.forms = this.checkEquipment(row.equipment_type).forms;
      });
      this.result = rows;
    },
    checkProperty(property) {
      findParentInArray.result = false;
      findParentInArray.asset = null;
      return findParentInArray.search(this.assets, property.toLowerCase(), this.selected_tags);
    },
    checkEquipment(equipment) {
      if(equipment) {
        let item = this.equipment.find((eq) => eq.name.toLowerCase() == equipment.toLowerCase());
        if (item) {
          return {
            result: true,
            forms: item.forms.filter((form) => form.status == "published"),
            required_time_1: item.required_time_1,
            required_time_2: item.required_time_2,
            required_time_3: item.required_time_3,
          };
        }
      }
      return {
        result: false,
        forms: [],
      };
    },
    processResults() {
      this.processing = true;
      this.show_select = false;
      this.to_be_processed = [];

      this.selected.forEach((row) => {
        if (!row.tag_nr && row.cable_nr) {
          row.tag_nr = row.cable_nr;
        }
        else if (!row.cable_nr && row.tag_nr) {
          row.cable_nr = row.tag_nr;
        }
      });

      //THIS BLOCK USED FOR CABLES ONLY BECAUSE ONLY CABLES HAVE "FROM" AND "TO" FIELDS
      if (this.selected_fieldset.name === 'Cables') {
        // Group by equipment type and process per form
        let equipment_types = [];
        this.selected.forEach((node) => {
          if (!equipment_types.find((type) => type == node.equipment_type)) {
            equipment_types.push(node.equipment_type);
          }
        });
        //example of equipment_types: ["INSTRUMENT CABLE", "LV CABLE"]
        equipment_types.forEach((type) => {
          //get all rows with current equipment type
          let run = this.selected.filter((row) => row.equipment_type == type);

          let equipment = this.checkEquipment(type);

          if(this.group_documents) {
            let document = {
              import_id: run[0].import_id,
              has_forms: true,
              platform_id: this.selectedProject.platform_id,
              project_name: this.selectedProject.name.trim(),
              platform_name: this.selectedProject.platform_name.trim(),
              tags: this.selected_tags,
              header: this.html_header,
              rows: [],
            };

            if (equipment.result) {
              equipment.forms.forEach((form) => {
                if (form.status === "published") {
                  form.project_id = this.selectedProject.id;
                  document.form = form;
                  document.html = form.html.trim();
                  if (this.selectedProject.afe_pef) {
                    document.document_name = form.description.trim() + " (" + this.selectedProject.afe_pef.trim() + ")";
                  } else {
                    document.document_name = form.description.trim();
                  }

                  document.attach_to = [];
                  run.forEach((result_row) => {
                    if (this.checkProperty(result_row.from).result) {
                      document.attach_to.push(this.checkProperty(result_row.from).asset.id);
                    }
                    if (this.checkProperty(result_row.to).result) {
                      document.attach_to.push(this.checkProperty(result_row.to).asset.id);
                    }
                  });
                  document.attach_to = [...new Set(document.attach_to)];

                  // Search and replace tags
                  this.setFields();
                  this.fields.forEach((field) => {
                    if (document.html) {
                      document.html = document.html.replaceAll("{{" + field.label + "}}", field.value);
                    }
                    document.form.content.push({
                      id: this.uid(),
                      label: field.text,
                      value: field.value,
                      placeholder: field.label,
                      type: "text-field",
                      properties: [],
                      children: [],
                      rules: [],
                      icon: "mdi-format-text",
                    });
                  });

                  // Prepare attributes and Set table header
                  let attributes_row = this.getAttributes(document, "");

                  // Set table rows and cells
                  let tables = "";
                  for(let x = 0; x < run.length; x++) {
                    tables = tables.concat(
                        "<table class='main-table'>" +
                          "<tr>" +
                            this.generateRow(run[x]) +
                          "</tr>" +
                          "<tr>" +
                            "<td colspan='" + this.getHeaderCount() + "'>" +
                              "<table class='sub-table'>" +
                                "<tr>" +
                                  attributes_row +
                                "</tr>" +
                              "</table>" +
                            "</td>" +
                          "</tr>" +
                        "</table>");
                  }

                  document.html = document.html.replace("{{attributes}}", tables);
                  this.to_be_processed.push(document);
                }
              });
            }
          }
          else {
            for(let x = 0; x < run.length; x++) {
              let document = {
                import_id: run[x].import_id,
                has_forms: true,
                platform_id: this.selectedProject.platform_id,
                project_name: this.selectedProject.name.trim(),
                platform_name: this.selectedProject.platform_name.trim(),
                tags: this.selected_tags,
                header: this.html_header,
                rows: [],
              };

              if (equipment.result) {
                equipment.forms.forEach((form) => {
                  if (form.status === "published") {
                    form.project_id = this.selectedProject.id;
                    document.form = form;
                    document.html = form.html.trim();
                    document.document_name = form.description.trim() + " (" + this.shortName(run[x].tag_nr) + ")";

                    document.attach_to = [];
                    if (this.checkProperty(run[x].from).result) {
                      document.attach_to.push(this.checkProperty(run[x].from).asset.id);
                    }
                    if (this.checkProperty(run[x].to).result) {
                      document.attach_to.push(this.checkProperty(run[x].to).asset.id);
                    }
                    document.attach_to = [...new Set(document.attach_to)];

                    // Search and replace tags
                    this.setFields();
                    this.result_headers.forEach((header_field) => {
                      this.fields.push({
                        label: header_field.value,
                        text: header_field.text,
                        value: run[x][header_field.value],
                      });
                    });
                    this.fields.forEach((field) => {
                      if (document.html) {
                        document.html = document.html.replaceAll("{{" + field.label + "}}", field.value);
                      }
                      document.form.content.push({
                        id: this.uid(),
                        label: field.text,
                        value: field.value,
                        placeholder: field.label,
                        type: "text-field",
                        properties: [],
                        children: [],
                        rules: [],
                        icon: "mdi-format-text",
                      });
                    });

                    //CUSTOM FIX TO REPLACE CABLE NR AND TAG NR BECAUSE THEY ARE DIFFERENT IN FIELDSETS
                    if (document.html) {
                      document.html = document.html.replaceAll("{{cable_nr}}", run[x].tag_nr);
                      document.html = document.html.replaceAll("{{tag_nr}}", run[x].cable_nr);
                    }

                    // Prepare attributes and Set table header
                    let attributes_row = this.getAttributes(document, "");

                    // Set table rows and cells
                    let table = "<table class='main-table'>" +
                                  "<tr>" +
                                    this.generateRow(run[x]) +
                                  "</tr>" +
                                  "<tr>" +
                                    "<td colspan='" + this.getHeaderCount() + "'>" +
                                      "<table class='sub-table'>" +
                                        "<tr>" +
                                          attributes_row +
                                        "</tr>" +
                                      "</table>" +
                                    "</td>" +
                                  "</tr>" +
                                "</table>";

                    document.html = document.html.replace("{{attributes}}", table);
                    this.to_be_processed.push(document);
                  }
                });
              }
            }
          }
        });
      }
      else {
        this.selected.forEach((row) => {
          let equipment = this.checkEquipment(row.equipment_type);
          let document = {
            import_id: row.import_id,
            asset_name: row.tag_nr.trim(),
            platform_id: this.selectedProject.platform_id,
            project_name: this.selectedProject.name.trim(),
            platform_name: this.selectedProject.platform_name.trim(),
            equipment_type: row.equipment_type + ': ' + row.tag_nr.trim(),
            tags: this.selected_tags,
            header: this.html_header,
            selected_targets: [],
            rows: [],
          };

          if(this.selectedProject.type === 'project_type_1') {
            document.required_time = equipment.required_time_1;
          }
          else if(this.selectedProject.type === 'project_type_2') {
            document.required_time = equipment.required_time_2;
          }
          else if(this.selectedProject.type === 'project_type_3') {
            document.required_time = equipment.required_time_3;
          }

          //Configure target array. Exists only if the excel file has import property. Cables don`t have it.
          //Example of import property in excel: UTILITIES/PUBLIC ANOUNCEMENT/MEZZANINEDECK
          row.selected_targets.forEach((target) => {
            document.selected_targets.push({
              asset_name: this.shortName(target),
              asset_longname: this.longName(target),
              add_document: false,
            });
          });
          // Add tagnr on which to attach documents
          document.selected_targets.push({
            asset_name: this.shortName(row.tag_nr),
            asset_longname: this.longName(row.tag_nr),
            add_document: true,
          });

          // With forms
          if (equipment.result && equipment.forms.length > 0) {
            row.forms.forEach((form) => {
              if (form.status === "published") {
                let form_document = this.$lodash.cloneDeep(document);
                form_document.has_forms = true;
                form.project_id = this.selectedProject.id;
                form_document.form = form;
                form_document.document_name = form.description.trim() + " (" + this.shortName(row.tag_nr) + ")";
                form_document.html = form.html.trim();
                // Search and replace tags
                this.setFields();
                this.result_headers.forEach((header_field) => {
                  this.fields.push({
                    label: header_field.value,
                    text: header_field.text,
                    value: row[header_field.value],
                  });
                });
                this.fields.forEach((field) => {
                  if (form_document.html) {
                    form_document.html = form_document.html.replaceAll("{{" + field.label + "}}", field.value);
                  }
                  form_document.form.content.push({
                    id: this.uid(),
                    label: field.text,
                    value: field.value,
                    placeholder: field.label,
                    type: "text-field",
                    properties: [],
                    children: [],
                    rules: [],
                    icon: "mdi-format-text",
                  });
                });

                let table = "<table class='main-table'>" +
                              "<tr>" +
                                "<td style='font-size:75%'>Tag Number</td>" +
                                this.getAttributes(form_document, "<td style='height:20px'>" + form_document.asset_name + "</td>") +
                              "</tr>" +
                            "</table>" +
                            "<br/>";

                form_document.html = form_document.html.replace("{{attributes}}", table);
                this.to_be_processed.push(form_document);
              }
            });
          }
          // Without forms
          else {
            document.has_forms = false;
            this.to_be_processed.push(document);
          }
        });
      }

      console.log(this.to_be_processed);

      this.processDocuments();
    },
    processDocuments() {
      this.loading = true;
      let document = this.to_be_processed[this.processDocumentsCounter];
      if(document.has_forms) {
        document.html = document.html.replace(/\{{.*?\}}/gi, "");
      }
      this.$http
          .post(this.$store.getters.appUrl + "v2/documents/process", document)
          .then(() => {
            this.result = this.result.filter((row) => row.import_id != document.import_id);
            this.selected = this.selected.filter((row) => row.import_id != document.import_id);
          }).catch(() => {
            this.loading = false;
            this.processing = false;
            this.selected = [];
            this.$toasted.error(this.$store.getters.translate("import_complete_with_errors"));
            this.show_select = true;
          }).finally(async () => {
                const waiting = new Promise(() => {
                setTimeout(() => {
                  this.processDocumentsCounter++;
                  if(this.to_be_processed.length > this.processDocumentsCounter) {
                    this.processDocuments();
                  }
                  else {
                    this.processDocumentsCounter = 0;
                    this.$http
                        .get(this.$store.getters.appUrl + "v2/projects/recount-time-in-job/" + this.selectedProject.id)
                        .then(() => {
                          this.loading = false;
                          this.processing = false;
                          this.selected = [];
                          this.$toasted.success(this.$store.getters.translate("import_complete"));
                          this.show_select = true;
                          this.loadAssets();
                        }).catch((error) => {
                          if (this.$store.getters.isLoggedIn) {
                            this.$toasted.error(error);
                          }
                          this.loading = false;
                        });
                  }
                }, 3000);
              })
              await Promise.any([waiting]);
          });
    },
    getAttributes(document, tds) {
      let attributes_row = "";
      let attributes = document.form.content.find((field) => field.placeholder == "attributes");
      if (attributes) {
        attributes.children.forEach((attribute) => {
          attributes_row = attributes_row.concat("<td style='font-size:75%'>" + attribute.label + ":</td>");
          tds = tds.concat("<td style='height:20px'></td>");
        });
      }

      attributes_row = attributes_row.concat("</tr><tr>" + tds);

      return attributes_row;
    },
    getHeaderCount() {
      let count = 0;
      this.selected_fieldset.content.forEach((header_field) => {
        if (header_field.key !== "import_property" && header_field.key !== "equipment_type") {
          count++;
        }
      });
      return count;
    },
    generateRow(row) {
      let new_row = "";
      this.selected_fieldset.content.forEach((header_field) => {
        if (header_field.key !== "import_property" && header_field.key !== "equipment_type" && header_field.key !== "afe_pef") {
          new_row = new_row.concat("<th style='text-align:left'><small>" + header_field.label + "</small>: <strong> " + row[header_field.key] + "</strong></th>");
        }
      });
      return new_row;
    },
    longName(str) {
      let matches = str.match(/\((.*?)\)/);
      if (matches) {
        return matches[1].trim();
      }
      return null;
    },
    shortName(str) {
      return str.replace(/ *\([^)]*\) */g, "").trim().toUpperCase();
    },
    setFields() {
      this.fields = [
        {
          label: "project-name",
          value: this.selectedProject.name,
          text: "Project",
        },
        {
          label: "platform-name",
          value: this.selectedProject.platform_name,
          text: "Platform",
        },
        {
          label: "tags",
          value: this.tagNames,
          text: "Tags",
        },
        {
          label: "workorders",
          value: this.tagNames,
          text: "Workorders",
        },
        {
          label: "afe_pef",
          value: this.selectedProject.afe_pef != null ? this.selectedProject.afe_pef : "",
          text: "AFE / PEF",
        },
        {
          label: "project-description",
          value: this.selectedProject.description != null ? this.selectedProject.description : "",
          text: "Project description",
        },
      ];
    },
    reset() {
      this.result = [];
      this.to_be_processed = [];
      this.selected = [];
      this.result_headers = [];
      this.data_table_headers = [];
    },
    uid() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0, v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
    async loadAssets() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/assets" + "?selected_platforms=" + this.selectedProject.platform_id + "&full_info=true")
          .then((response) => {
            this.loading = false;
            this.assets = response.data;
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    async getProjects() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/projects?tags=true")
          .then((response) => {
            this.loading = false;
            this.projects = response.data.data;
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    async getEquipment() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/equipment?forms=true")
          .then((response) => {
            this.loading = false;
            this.equipment = response.data.data;
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    async getFieldsets() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/fieldsets?full_info=true")
          .then((response) => {
            this.loading = false;
            this.fieldsets = response.data.data;
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
  },
  computed: {
    selectedProject() {
      if (this.project_id) {
        return this.projects.filter((project) => project.id == this.project_id)[0];
      } else {
        return null;
      }
    },
    groupBy() {
      if(this.group_documents) {
        return "equipment_type";
      }
      return null;
    },
    tagNames() {
      let tag_names = [];
      this.selected_tags.forEach((tag) => {
        let name = tag.name.en;
        tag_names.push(name);
      });
      return tag_names.join(", ");
    },
    tagIds() {
      let tag_ids = [];
      this.selected_tags.forEach((tag) => {
        tag_ids.push(tag.id);
      });
      return tag_ids;
    },
  },
  watch: {
    selected_fieldset: {
      handler() {
        this.group_documents = this.selected_fieldset.group;
      },
    },
  }
};
</script>

<style scoped>
.top-section {
  display: none !important;
}

.menuable__content__active {
  /*Fix for Safari dialog display. Reference Vuetify issue #2111 */
  z-index: 250 !important;
}
</style>