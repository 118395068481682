<template>
  <v-btn :loading="loading" text @click.prevent="go()" color="primary">Import</v-btn>
</template>

<script>
import FlatfileImporter from "flatfile-csv-importer";

export default {
  props: ["fields", "title"],
  data() {
    return {
      loading: false,
      importer: null,
      config: {
        license: process.env.VUE_APP_FLATFILE_LICENSE,
        config: {
          fields: [],
          type: this.title,
          allowInvalidSubmit: false,
          managed: true,
          allowCustom: true,
          disableManualInput: false,
          styleOverrides: {
            borderRadius: "4px", // Flatfile wrapper, modals, & buttons**
            primaryButtonColor: "#7BA5B6", // Primary buttons*
            uploadButtonColor: "#7BA5B6", // Upload buttons*
            linkColor: "#7BA5B6", // Links*
            linkAltColor: "#4a90e2", // :hover, :active, & :focus states of links*
            primaryTextColor: "#7BA5B6", // Main text color, background-color of certain buttons*
            secondaryTextColor: "#9daab6", // 'Dimmed' text*
            errorColor: "#d0011b", // Invalid and error indicator borders, icons, and text*
            successColor: "#60b700", // Success text, buttons, icons, toggles, required indicators*
            warningColor: "#f6a623", // Warning text and icons*
            borderColor: "#e8e8e8", // Flatfile wrapper, modals, and some buttons*
            fontFamily: '"museosansw01-rounded300Rg", sans-serif' // Main font***
          }
        }
      },
      results: []
    };
  },
  methods: {
    go() {
      this.load();
      this.loading = true;
    },
    load() {
      this.config.config.fields = this.fields;
      this.config.config.type = this.title;
      FlatfileImporter.setVersion(2);
      this.importer = new FlatfileImporter(this.config.license, this.config.config);
      this.importer.registerRecordHook((record, index) => {
        console.log(index);
        let out = {};
        if (record.equipment_type) {
          out.equipment_type = {
            value: record.equipment_type.toLowerCase()
          };
        }
        return out;
      });
      this.launch();
    },
    launch() {
      const $v = this;
      this.importer.setCustomer({
        userId: "perscriptum",
        email: "dummy@pernexus.org",
      });
      this.importer
        .requestDataFromUser()
        .then(function(results) {
          $v.results = JSON.stringify(results.validData, null, 2);
          $v.importer.displaySuccess("Success!");
          $v.import(results.validData);
        })
        .catch(function(error) {
          console.info(error || "window close");
           $v.loading = false;
        });
    },
    import(results) {
      results.forEach(result => {
        result.is_selectable = true;
      });
      this.$emit("result", results);
      this.loading = false;
    },
  },
};
</script>