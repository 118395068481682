const findParentInArray = {
    result: false,
    asset: null,
    search(items, search_term, selected_tags) {
        findParentInArray.traverse(items, search_term, selected_tags);
        return findParentInArray;
    },
    traverse(items, search_term, selected_tags) {
        items.forEach(item => {
            var tag_exists = false;
            for (let i = 0; i < selected_tags.length; i++) {
                if (item.tags.includes(selected_tags[i].name.en)) {
                    tag_exists = true;
                    break;
                }
            }
            if (tag_exists && item.name.replace(/\(.*?\)/gi, '').replace(/\s/g, '').toLowerCase() == search_term.replace(/\(.*?\)/gi, '').replace(/\s/g, '').toLowerCase()) {
                findParentInArray.result = true;
                findParentInArray.asset = item;
            }
            if(item.children) {
                findParentInArray.traverse(item.children, search_term, selected_tags);
            }
        })
    }
}

export default findParentInArray